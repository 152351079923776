// Entry point for the build script in your package.json

import Gallery from "./gallery"
import feather from 'feather-icons'


window.addEventListener('DOMContentLoaded', function() {
  feather.replace()
    const gallery = document.querySelector('.js_gallery')
    if ( gallery ) {
      new Gallery(gallery)
    }
    const btn = document.querySelector('.trigger_menu')
    btn.addEventListener("click", () => {
      const list = document.querySelector('.mobile_nav_list')
      list.classList.toggle("open")
    })
  
})
