import gsap from 'gsap'

export default class Gallery {
    constructor(el) {
        this.images =  Array.from(el.querySelectorAll('.js_item'))
        this.popup = document.querySelector('.full_image')
        this.image = ''
        this.close = document.querySelector('.full_image_close')
        this.init()
    }

    init(){
       this.images.forEach((img) => {
        img.addEventListener("click", () => {
            this.image = img.dataset.target
            this.openPop()
        })
       })
       this.close.addEventListener("click", () => {
           this.closePop()
       })
    }

    openPop() {
        this.popup.querySelector("img").setAttribute("src",this.image)
        
        this.popup.classList.add("open")
    }
    closePop() {
        this.popup.querySelector("img").setAttribute("src","")
        
        this.popup.classList.remove("open")
    }
}